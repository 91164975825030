/*
* Default styles
* https://css-tricks.com/snippets/css/a-guide-to-flexbox/
*/
.container {
	width: 100%;
	height: 100%;
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
}
.show-print {
	display: none;
}

[class*='resume-'] {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.half-container {
	width: 50%;
}

@font-face {
	font-family: 'Fira Code Nerd Font';
	src: url('FiraCodeNF-Reg.woff2') format('woff2');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

body {
	margin: 0;
	font-family: 'Fira Code Nerd Font', 'Times New Roman', monospace;
}

h1 {
	text-decoration: underline;
}

/*
* End Default styles
*/

/*
* Parallax
* Author: CMDW (candra-shalahuddin) https://codepen.io/cmdw/pen/vQqzyB
*/

.parallax1 > use {
	animation: move-forever1 10s linear infinite;
	&:nth-child(1) {
		animation-delay: -2s;
	}
}
.parallax2 > use {
	animation: move-forever2 8s linear infinite;
	&:nth-child(1) {
		animation-delay: -2s;
	}
}
.parallax3 > use {
	animation: move-forever3 6s linear infinite;
	&:nth-child(1) {
		animation-delay: -2s;
	}
}
.parallax4 > use {
	animation: move-forever4 4s linear infinite;
	&:nth-child(1) {
		animation-delay: -2s;
	}
}
@keyframes move-forever1 {
	0% {
		transform: translate(85px, 0%);
	}
	100% {
		transform: translate(-90px, 0%);
	}
}
@keyframes move-forever2 {
	0% {
		transform: translate(-90px, 0%);
	}
	100% {
		transform: translate(85px, 0%);
	}
}
@keyframes move-forever3 {
	0% {
		transform: translate(85px, 0%);
	}
	100% {
		transform: translate(-90px, 0%);
	}
}
@keyframes move-forever4 {
	0% {
		transform: translate(-90px, 0%);
	}
	100% {
		transform: translate(85px, 0%);
	}
}

/*
* End Parallax
* Author: CMDW (candra-shalahuddin) https://codepen.io/cmdw/pen/vQqzyB
*/

/*
* Resume styles
*/
.resume-header {
	background: #2280ec;
}
footer {
	background-color: #2280ec;
	border-radius: 50%/3rem 3rem 0 0;
}

footer a {
	padding: 1rem;
}

.resume-about p {
	padding: 0 3rem 0 3rem;
}

.header-wave {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.profile-image {
	width: 2rem;
}

.profile-button-container {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.contact-button-container {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.profile-button {
	background-color: white;
	color: black;
	border: 3px solid #e7e7e7;
	text-decoration: none;
	display: flex;
	text-align: center;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	margin: 1rem;
	padding: 0.25rem;
	border-radius: 1rem;
	min-width: 10rem;
}

.contact-button {
	background-color: white;
	color: black;
	border: 3px solid black;
	text-decoration: none;
	display: flex;
	text-align: center;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	margin: 1rem;
	padding: 0.25rem;
	border-radius: 1rem;
	min-width: 10rem;
}

.profile-button img {
	padding-right: 0.5rem;
}

.contact-button img {
	padding-right: 0.5rem;
}

.profile-button:hover {
	background-color: #063cd3;
	transition: 0.3s;
	border: 0.19rem solid #063cd3;
}

.contact-button:hover {
	background-color: #063cd3;
	transition: 0.3s;
	border: 0.19rem solid #063cd3;
}

.Github {
	color: black;
	border: 0.19rem solid #007714;
}

.LinkedIn {
	color: black;
	border: 0.19rem solid #555555;
}

.Bitbucket {
	color: black;
	border: 0.19rem solid #3604aa;
}

.sub-title {
	font-size: 75%;
}

.skill-card-container {
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	justify-content: center;
}
.education-card-container {
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	justify-content: center;
}
.border-card {
	text-align: center;
	align-items: center;
	justify-content: center;
	padding: 1rem;
	border-radius: 1rem;
}

#flaticonFotter {
	color: black;
}

/*
* End Resume styles
*/

/*
* Media queries small screens
*/
@media screen and (max-width: 750px) {
	[class*='resume-'] {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
}
/*
* End Media queries small screens
*/

/*
* Media queries print
*/
@media print {
	a:visited {
		color: blue;
	}
	.header-wave {
		display: none;
	}
	.dont-print {
		display: none;
	}
	.show-print {
		display: flex;
	}
	@page {
		size: auto; /* auto is the initial value */
		margin-top: 3rem; /* this affects the margin in the printer settings */
	}
	div {
		break-inside: avoid;
		font-size: small;
		padding: 0;
	}
	h1 {
		padding: 0;
		margin: 0;
	}
	h2 {
		padding: 0;
		margin: 0;
	}
	h3 {
		padding: 0;
		margin: 0;
	}
	[class*='resume-'] {
		width: 50%;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	.border-card {
		padding: 0.3rem;
	}
	.resume-about {
		width: 100%;
	}
	.resume-header {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
}
/*
* End Media queries print
*/
