.container {
  flex-flow: wrap;
  justify-content: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.show-print {
  display: none;
}

[class*="resume-"] {
  flex-direction: column;
  align-items: center;
  width: 100%;
  display: flex;
}

.half-container {
  width: 50%;
}

@font-face {
  font-family: Fira Code Nerd Font;
  src: url("FiraCodeNF-Reg.3497d199.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

body {
  margin: 0;
  font-family: Fira Code Nerd Font, Times New Roman, monospace;
}

h1 {
  text-decoration: underline;
}

.parallax1 > use {
  animation: 10s linear infinite move-forever1;
}

.parallax1 > use:first-child {
  animation-delay: -2s;
}

.parallax2 > use {
  animation: 8s linear infinite move-forever2;
}

.parallax2 > use:first-child {
  animation-delay: -2s;
}

.parallax3 > use {
  animation: 6s linear infinite move-forever3;
}

.parallax3 > use:first-child {
  animation-delay: -2s;
}

.parallax4 > use {
  animation: 4s linear infinite move-forever4;
}

.parallax4 > use:first-child {
  animation-delay: -2s;
}

@keyframes move-forever1 {
  0% {
    transform: translate(85px);
  }

  100% {
    transform: translate(-90px);
  }
}

@keyframes move-forever2 {
  0% {
    transform: translate(-90px);
  }

  100% {
    transform: translate(85px);
  }
}

@keyframes move-forever3 {
  0% {
    transform: translate(85px);
  }

  100% {
    transform: translate(-90px);
  }
}

@keyframes move-forever4 {
  0% {
    transform: translate(-90px);
  }

  100% {
    transform: translate(85px);
  }
}

.resume-header {
  background: #2280ec;
}

footer {
  background-color: #2280ec;
  border-radius: 50% / 3rem 3rem 0 0;
}

footer a {
  padding: 1rem;
}

.resume-about p {
  padding: 0 3rem;
}

.header-wave {
  flex-direction: column;
  align-items: center;
  width: 100%;
  display: flex;
}

.profile-image {
  width: 2rem;
}

.profile-button-container, .contact-button-container {
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
}

.profile-button {
  color: #000;
  text-align: center;
  cursor: pointer;
  background-color: #fff;
  border: 3px solid #e7e7e7;
  border-radius: 1rem;
  justify-content: center;
  align-items: center;
  min-width: 10rem;
  margin: 1rem;
  padding: .25rem;
  text-decoration: none;
  display: flex;
}

.contact-button {
  color: #000;
  text-align: center;
  cursor: pointer;
  background-color: #fff;
  border: 3px solid #000;
  border-radius: 1rem;
  justify-content: center;
  align-items: center;
  min-width: 10rem;
  margin: 1rem;
  padding: .25rem;
  text-decoration: none;
  display: flex;
}

.profile-button img, .contact-button img {
  padding-right: .5rem;
}

.profile-button:hover, .contact-button:hover {
  background-color: #063cd3;
  border: .19rem solid #063cd3;
  transition: all .3s;
}

.Github {
  color: #000;
  border: .19rem solid #007714;
}

.LinkedIn {
  color: #000;
  border: .19rem solid #555;
}

.Bitbucket {
  color: #000;
  border: .19rem solid #3604aa;
}

.sub-title {
  font-size: 75%;
}

.skill-card-container, .education-card-container {
  flex-flow: wrap;
  justify-content: center;
  display: flex;
}

.border-card {
  text-align: center;
  border-radius: 1rem;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}

#flaticonFotter {
  color: #000;
}

@media screen and (width <= 750px) {
  [class*="resume-"] {
    flex-direction: column;
    align-items: center;
    width: 100%;
    display: flex;
  }
}

@media print {
  a:visited {
    color: #00f;
  }

  .header-wave, .dont-print {
    display: none;
  }

  .show-print {
    display: flex;
  }

  @page {
    size: auto;
    margin-top: 3rem;
  }

  div {
    break-inside: avoid;
    padding: 0;
    font-size: small;
  }

  h1, h2, h3 {
    margin: 0;
    padding: 0;
  }

  [class*="resume-"] {
    flex-direction: column;
    align-items: center;
    width: 50%;
    display: flex;
  }

  .border-card {
    padding: .3rem;
  }

  .resume-about {
    width: 100%;
  }

  .resume-header {
    flex-direction: column;
    align-items: center;
    width: 100%;
    display: flex;
  }
}
/*# sourceMappingURL=index.05862a20.css.map */
